import { Container } from '@finn/atoms';
import { Button } from '@finn/design-system';
import { GalleryCosmicMetadata } from '@finn/ui-cosmic';
import { Slider } from '@finn/ui-modules';
import { getCloudinaryImgUrl } from '@finn/ui-utils';
import { Box, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React, { useRef } from 'react';

import { parseToHtml } from '~/utils/html';

import GalleryItem from './GalleryItem';

type StyledProps = {
  gridRepeat: number;
  totalImages: number;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(3),
    gridTemplateColumns: (props: StyledProps) =>
      `repeat(${props.gridRepeat}, ${
        props.totalImages === 1 ? '100%' : '80%'
      })`,
    gridTemplateRows: `${theme.customStyles.gallery.rowHeight.sm}px ${theme.customStyles.gallery.rowHeight.sm}px`,
    [theme.breakpoints.up('sm')]: {
      gridTemplateRows: `${theme.customStyles.gallery.rowHeight.md}px ${theme.customStyles.gallery.rowHeight.md}px`,
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: (props: StyledProps) =>
        `repeat(${props.gridRepeat}, ${
          props.totalImages === 1 ? '100%' : '50%'
        })`,
      gridTemplateRows: (props: StyledProps) =>
        `${
          props.totalImages === 1
            ? theme.customStyles.gallery.rowHeightSingle.lg
            : theme.customStyles.gallery.rowHeight.lg
        }px ${
          props.totalImages === 1
            ? theme.customStyles.gallery.rowHeightSingle.lg
            : theme.customStyles.gallery.rowHeight.lg
        }px`,
    },
  },
  head: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(5),
    },
  },
}));

interface IProps {
  data: GalleryCosmicMetadata;
}

const getImageContainer = (total) => {
  return total === 1 ? ({ children }) => <div>{children}</div> : Container;
};

const Gallery: React.FunctionComponent<IProps> = ({ data }) => {
  const totalImages = data?.images?.length;
  const blockOfThree = Math.floor(totalImages / 3);
  const remaining = totalImages % 3;
  const gridRepeat = blockOfThree * 2 + remaining;
  const classes = useStyles({ gridRepeat, totalImages });
  const firstItemRef = useRef();
  const lastItemRef = useRef();
  const ImageContainer = getImageContainer(totalImages);

  const hasButton = !!data.button_href;

  return (
    <>
      <Container>
        <Box className={classes.head}>
          <h2
            className={cn('mobile-t2-semibold md:web-t2-semibold', {
              'mb-6': hasButton,
            })}
          >
            {parseToHtml(data.title)}
          </h2>
          {hasButton && (
            <Button href={data.button_href}>Download press pack as .zip</Button>
          )}
        </Box>
      </Container>
      <Slider
        isGallery
        firstItemForwardedRef={firstItemRef}
        lastItemForwardedRef={totalImages === 1 ? firstItemRef : lastItemRef}
      >
        <ImageContainer>
          <Box className={classes.wrapper}>
            {data.images.map(({ metadata }, index) => (
              <GalleryItem
                key={metadata.large.url}
                url={getCloudinaryImgUrl(metadata.large.url, {
                  w: data.images.length === 1 ? 2100 : 618,
                  c: 'fill',
                })}
                index={index}
                totalImages={totalImages}
                ref={
                  index === 0
                    ? firstItemRef
                    : index === totalImages - 1
                      ? lastItemRef
                      : null
                }
                alt={metadata.alt}
              />
            ))}
          </Box>
        </ImageContainer>
      </Slider>
      {data.description ? (
        <Container>
          <p className="body-12-regular">{data.description}</p>
        </Container>
      ) : null}
    </>
  );
};

export default Gallery;
